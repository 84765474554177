require('./bootstrap');

//import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox";

//import "@fancyapps/ui/dist/fancybox.css";

// Importamos jquery
import $ from 'jquery';

// Importamos slick
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

window.$ = window.jQuery = $;

// Importamos dropzone
//import { Dropzone } from "dropzone";

// If you are using an older version than Dropzone 6.0.0,
// then you need to disabled the autoDiscover behaviour here:
//Dropzone.autoDiscover = false;
